<template>
  <div class="isCalling">
      <div class="com-number-wrapper">
        <div class="com-number">{{callNumber}}</div>
        <div class="com-status">{{status}}</div>
      </div>
      <div class="receive-call-wrapper">
        <div class="reply">
          <span class="reply-icon reject" @click="hangUp">
            <i class="el-icon-phone"></i>
          </span>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
const callStatusText = {
  started: '呼叫中...',
  ringing: '响铃中...',
  answered: '已接听',
  completed: '通话结束',
  busy: '忙线',
  timeout: '超时',
  unanswered: '未接听',
  rejected: '已拒绝',
  failed: '通话失败'
}
export default {
  computed: {
    ...mapGetters({ callStatus: 'currentCallStatus', callNumber: 'currentCallNumber', outboundCall: 'currentCall' }),
    status() {
      return callStatusText[this.callStatus]
    }
  },
  methods: {
	// 挂断电话
    hangUp() {
      if (this.outboundCall) {
        if (this.outboundCall.tozo_hasHangup) return
        this.outboundCall.tozo_hasHangup = true
        this.outboundCall.hangUp()
        // console.log('已挂断电话')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .isCalling{
    height: 700px;
    @media screen and (max-width: 999px){
      height: auto;
    }
  .com-number-wrapper{
    padding: 50px 0;
    text-align: center;
    .com-number{
      height: 30px;
      font-size: 30px;
    }
    .com-status{
      margin-top: 20px;
    }
  }
  .receive-call-wrapper{
    text-align: center;
    .reply{
      display: inline-block;
      .reply-icon{
        display: inline-block;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background-color: red;
        cursor: pointer;
        &.answer{
          margin-left: 100px;
          background-color: #0ab336;
        }
        .el-icon-phone{
          height: 65px;
          line-height: 65px;
          font-size: 30px;
          color: white;
        }
      }
    }
  }
}
</style>
