<template>
  <div class="recent-log">
    <div class="recent-log-title">
      最近来电:
    </div>
    <ul class="recent-log-list">
      <li class="recent-log-item" v-for="(item, index) in list" :key="index">
        <div class="item-num">{{item.number}}</div>
        <div class="item-desc"><span class="item-desc-date">{{item.date}}</span><span :class="[item.status !== 'completed' ? 'red' : '']">{{item.status | statusFormat}}</span></div>
      </li>
    </ul>
    <!-- <div class="recent-lot-note"> Note: 临时记录，刷新后将会消失</div> -->
  </div>
</template>

<script>
const statusText = {
  failed: '失败',
  cancelled: '取消',
  rejected: '已拒绝',
  busy: '正忙',
  timeout: '超时',
  completed: '通话结束',
  unanswered: '未接听',
  started: '未接听'
}
export default {
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  filters: {
    statusFormat(value) {
      return statusText[value]
    }
  }
}
</script>

<style lang="scss" scoped>
.recent-log{
  height: 800px;
  background-color: #fff;
  border-right: 1px solid #e6e6e6;
  .recent-log-title{
    padding-left: 10px;
    font-size: 12px;
    line-height: 3;
    color: #409eff;
  }
  .recent-log-list{
    cursor: default;
    .recent-log-item{
      padding: 15px 0 15px 15px;
      &:hover{
        background-color: rgb(230, 230, 230, .6);
      }
      .item-num{
        font-size: 16px;
        line-height: 1.5;
      }
      .item-desc{
        font-size: 12px;
        line-height: 1.5;
        color:rgb(73, 73, 73);
        .item-desc-date{
          margin-right: 10px;
        }
        .red{
          color: red;
        }
      }
    }
  }
  // .recent-lot-note{
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   padding-left: 10px;
  //   font-size: 12px;
  //   line-height: 2;
  //   color: rgb(194, 193, 193);
  // }
}
</style>
