<template>
  <div class="dial-container">
    <div v-loading="initing" element-loading-text="通话创建中" element-loading-spinner="el-icon-loading" element-loading-background="#f3f3f3" class="not-isCalling">
      <div class="com-number-wrapper">
        <div class="com-number">{{callNumber}} <i v-show="callNumber" @click="clearNumber" class="el-icon-error"></i></div>
      </div>
      <div class="com-dial-wrapper">
        <ul class="dial">
          <li class="dial-item" v-for="num in dial" :key="num" @click="pressNumber(num)">{{num}}</li>
          <li class="dial-item call-wrapper" @click="callOut">
            <span class="call-icon">
              <i class="el-icon-phone"></i>
            </span>
          </li>
          <li class="dial-item del-wrapper" @click="delNumber">
            <div>
              <div class="arrow"></div>
              <div class="del">x</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui'
export default {
  data() {
    return {
      dial: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#', '+'],
      callNumber: '',
      isBusy: false
    }
  },
  mounted() {
	  
  },
  computed: {
    app() {
      return this.$store.state.nexmoApp
    },
    callList() {
      return this.$store.state.callList
    },
    initing() {
      return this.$store.state.callIniting
    }
  },
  watch: {
    callList(list) {
      if (list.length === 0) {
        this.isBusy = false
      }else{
		  this.isBusy = true
	  }
    }
  },
  activated() {
    document.addEventListener('keydown', this.keydownHandler)
  },
  deactivated() {
    document.removeEventListener('keydown', this.keydownHandler)
  },
  methods: {
	// 拨打
    callOut() {
		const that = this
		if(!that.callNumber){
			that.isBusy = false
			Message({message: "请输入号码",duration: 1 * 1000,})
			if (that.isBusy) return
		}else{
			that.isBusy = true
			if (!that.app || that.callList.length) { return }
			that.app.callServer(that.callNumber).catch(
			  error => {
				that.$msgError('呼叫错误，error: ' + error)
				console.log(error)
				that.$store.commit('setCallIniting', false)
			  }
			)
			//切换通话创建中
			that.$store.commit('setCallIniting', true)
		}
    },
    pressNumber(num) {
      this.callNumber = this.callNumber + num
    },
    delNumber() {
      this.callNumber = this.callNumber.slice(0, this.callNumber.length - 1)
    },
    clearNumber() {
      this.callNumber = ''
    },
    keydownHandler(e) {
      if (this.dial.includes(e.key)) {
        this.pressNumber(e.key)
      }
      if (e.key === 'Backspace') {
        this.delNumber()
      }
      if (e.key === 'Enter') {
        this.callOut()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dial-container {
  position: relative;
  .not-isCalling {
    height: 700px;
    @media screen and (max-width: 999px){
      height: auto;
    }
    .com-number-wrapper {
      padding: 50px 0;
      @media screen and (max-width: 999px){
        padding: 20px 0;
      }
      text-align: center;
      .com-number {
        width: 560px;
        @media screen and (max-width: 999px){
          width: 90%;
          padding: 0 5%;
          text-align: center;
        }
        height: 30px;
        font-size: 30px;
        text-align: right;
        .el-icon-error {
          margin-left: 35px;
          color: #bdbcbc;
          cursor: pointer;
        }
      }
      .com-status {
        margin-top: 20px;
      }
    }
    .com-dial-wrapper {
      margin: 0 auto;
      width: 300px;
      border-radius: 20px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      .dial {
        width: 100%;
        .dial-item {
          display: inline-block;
          width: 100px;
          height: 80px;
          text-align: center;
          line-height: 80px;
          font-size: 30px;
          background-color: white;
          cursor: pointer;
          &:hover {
            background-color: #f9f9fb;
          }
          &.call-wrapper {
            .call-icon {
              display: inline-block;
              width: 50px;
              height: 50px;
              line-height: 50px;
              border-radius: 50%;
              background-color: #0ab336;
              cursor: pointer;
              .el-icon-phone {
                color: white;
              }
            }
          }
          &.del-wrapper {
            .del {
              position: relative;
              display: inline-block;
              margin-left: 5px;
              width: 20px;
              height: 20px;
              font-size: 20px;
              line-height: 17px;
              background-color: #bdbcbc;
              color: white;
              border-radius: 2px;
              &::before {
                position: absolute;
                left: -19px;
                content: '';
                width: 0;
                height: 0;
                border: 10px solid;
                border-color: transparent #bdbcbc transparent transparent;
              }
            }
          }
        }
      }
    }
  }
}
</style>
