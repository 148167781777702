<template>
  <div class="call-in">
    <div class="com-number-wrapper">
      <div class="com-number">{{callNumber}}</div>
      <div class="com-status">{{status}}</div>
    </div>
    <div class="receive-call-wrapper">
      <div class="reply">
        <span class="reply-icon reject" @click="reject" id="reject">
          <i class="el-icon-phone"></i>
        </span>
      </div>
      <div class="reply">
        <span class="reply-icon answer" v-show="showAnswerBtn" @click="answer" id="answer">
          <i class="el-icon-phone"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isStarted } from './utils/index.js'
const callStatusText = {
  started: '来电中...',
  ringing: '响铃中...',
  answered: '已接听',
  completed: '通话结束',
  busy: '忙线',
  timeout: '超时',
  unanswered: '未接听',
  rejected: '已拒绝',
  failed: '通话失败'
}
export default {
  computed: {
		...mapGetters({ callStatus: 'currentCallStatus', callNumber: 'currentCallNumber', inboundCall: 'currentCall' }),
		status() {
		  return callStatusText[this.callStatus]
		},
		showAnswerBtn() {
		  return isStarted(this.inboundCall?.status)
		}
	},
  methods: {
    reject() {
      if (this.inboundCall) {
		console.log("call49行的inboundCall");console.log(this.inboundCall);
        if (this.inboundCall.tozo_hasReject) return
        this.inboundCall.tozo_hasReject = true
        this.inboundCall.reject()
        // console.log('已挂断电话')
      }
    },
	//处理通话状态  正式接听
    answer() {
      if (this.inboundCall) {
        if (this.inboundCall.tozo_hasAnswer) return
        // console.log('不到这里')
        this.inboundCall.tozo_hasAnswer = true
        this.inboundCall.answer()
        // console.log('已接听电话')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.call-in{
  height: 700px;
  @media screen and (max-width: 999px){
    height: auto;
  }
  .com-number-wrapper{
    padding: 50px 0;
    text-align: center;
    .com-number{
      height: 30px;
      font-size: 30px;
    }
    .com-status{
      margin-top: 20px;
    }
  }
  .receive-call-wrapper{
    text-align: center;
    .reply{
      display: inline-block;
      .reply-icon{
        display: inline-block;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background-color: red;
        cursor: pointer;
        &.answer{
          margin-left: 100px;
          background-color: #0ab336;
        }
        .el-icon-phone{
          height: 65px;
          line-height: 65px;
          font-size: 30px;
          color: white;
        }
      }
    }
  }
}
</style>
