<template>
  <div
    class="communication"
    v-loading="loading"
    element-loading-text="初始化语音程序......"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
    :style="height"
    >
      <div v-if="isShowLogs" class="communication-log">
        <RecentLog :list="recentLogs" />
      </div>
	  
      <div class="communication-operation">
        <div class="info">
          <el-tag type="danger" v-show="waitingCount" class="waiting-tag">等待中的呼入:  {{waitingCount}} </el-tag>
        </div>
        <transition mode="out-in">
          <keep-alive>
            <component v-bind:is="currentComponent"></component>
          </keep-alive>
        </transition>
      </div>
  </div>
</template>

<script>
/* global NexmoClient */
import { mapGetters, mapState } from 'vuex'
import Dial from './Dial.vue'
import CallIn from './CallIn.vue'
import CallOut from './CallOut.vue'
import RecentLog from './RecentLog.vue'
import { isCompleted } from './utils/index.js'
import { vonageStatus, vonageAnswerTime} from '@/api/user.js'
import moment from 'moment'
// import VConsole from 'vconsole' 
// let vConsole = new VConsole();
var client = new  NexmoClient()
export default {
  components: { RecentLog, Dial, CallIn, CallOut },
  data() {
    return {
      loading: true,
      currentCallId: '',
      recentLogs: [],
      originalTitle: document.title,
      timer: null,
      currentComponent: Dial
    }
  },
  computed: {
    ...mapGetters(['currentCall']),
    ...mapState(['callList']),
    userJWT() {
      return this.$store.state.token
    },
    app() {
      return this.$store.state.nexmoApp
    },
    waitingCount() {
      if (this.callList.length > 1) {
        return this.callList.length - 1
      } else {
        return 0
      }
    },
    height() {
      if (this.$store.state.isMobile) {
        const height = (window.innerHeight - 61) + 'px'
        return { height }
      } else {
        return {}
      }
    },
    isShowLogs() {
      return !this.$store.state.isMobile
    }
  },
  watch: {
    callList(list) {
      if (list.length === 1 &&
      list[0].direction === this.currentCall.CALL_DIRECTION.INBOUND &&
      list[0].status !== 'started') {
        this.finishShineTitle()
		// console.log("com78行，呼入监听");console.log(list[0].direction);
      }
    }
  },
  mounted() {
    this.initNexmoClient();
	/*禁止缩放safari浏览器*//* 阻止双击放大*/
	// window.onload = function () {
	//     var lastTouchEnd = 0;
	//     document.addEventListener("touchstart", function (event) {
	//         if (event.touches.length > 1) {
	//             event.preventDefault();
	//         }
	//     });
	//     document.addEventListener("touchend", function (event) {
	//         var now = (new Date()).getTime();
	//         if (now - lastTouchEnd <= 300) {
	//             event.preventDefault();
	//         }
	//         lastTouchEnd = now;
	//     }, false);/* 阻止双指指掐放大*/
	//     document.addEventListener("gesturestart", function (event) {
	//         event.preventDefault();
	//     });
	// }
  },
  methods: {
    finishShineTitle() {
      clearInterval(this.timer)
      this.timer = null
      document.title = this.originalTitle
    },
	// 挂断之后浏览器停止顶部闪烁标题 待接听列表为空时才停止	
	StopFlashing(){
		if(this.callList.length == 0){
			clearInterval(this.timer)
			document.title = 'vonage-project'
		}else{
			return
		}
	},
	// 来电提示
    shineTitle() {
      clearInterval(this.timer)
	  // 浏览器顶部闪烁标题
      this.timer = setInterval(function() {
        const text = '【您有新的来电】'
        document.title = document.title === text ? '' : text
      }, 300)
    },
	// 添加来电记录
    addLog(call) {
      const number = call.from || 'unknown'
      let date = call.conversation.timestamp.created
      const status = call.status
      const day = date.slice(5, 10)
      let hour = Number(date.slice(11, 13)) + 8
      if (hour.toString().length === 1) {
        hour = '0' + hour
      }
      const minute = date.slice(14, 16)
      date = day + ' ' + hour + ':' + minute
      this.recentLogs.unshift({ number, date, status })
	  
      if (this.recentLogs.length > 10) {
        this.recentLogs.pop()
      }
    },
	// 获取接听时间
	post_vonageAnswerTime(vonageAnswerTime_Time) {
	  console.log(vonageAnswerTime_Time)
	  vonageAnswerTime(vonageAnswerTime_Time).then((res) => {
		console.log(res)
	  }).catch((err) => {
	    console.log(err)
	  })
	},
	// 更新来电记录状态 或 拨打记录状态
	post_vonageStatus(vonageStatus_log) {
	  console.log(vonageStatus_log)
	  vonageStatus(vonageStatus_log).then((res) => {
		console.log(res)
	  }).catch((err) => {
        console.log(err)
      })
	},
	// unanswered
    // 初始化程序  直接调用第三方sdk函数
    initNexmoClient() {
      const vm = this
      // const client = new  NexmoClient(/* { debug: true }*/)
      vm.$store.commit('setClient', client)
      client.on('disconnect', () => {
        vm.$msgError('程序未连接,请重新刷新')
      })
      client.on('error', (error) => {
        console.log('error', error) 
      })
	  // userJWT为平台token
      if (!vm.userJWT) {
        vm.$msgError('账号未登录或登录过期')
        return vm.$router.push('/user')
      }
      client.login(vm.userJWT).then(
        app => {
			  console.log(app)
          vm.$store.commit('setNexmoApp', app)
          vm.onMemberCall()
          vm.onCompletedCallStatus()
          vm.onTokenExpired()
          vm.loading = false
        },
        error => {
			  console.log(error)
          if (error.type === 'system:error:expired-token') {
            vm.$store.commit('clearToken')
            vm.$router.push('/user')
          } else {
            vm.$msgError('登录错误')
          }
        }
      )
	  return true;
    },
	
    //监听开始
	//参考文档的给对方打电话这块
    onMemberCall() {
      const vm = this
      vm.app.on('member:call', (member, call) => {
        vm.toCommunicationRoute()
        vm.$store.commit('addCall', call)
		//direction：inbound为呼入 direction：outbound 呼出
        if (call.direction === call.CALL_DIRECTION.INBOUND) {
		  // 浏览器底部弹出通知提示
          vm.$pop('您有新的来电!'+ "             "+ "点击查看", { body: call.from || 'unknown'})
          vm.shineTitle()
          if (vm.currentComponent === Dial) {
			// 切换电话界面
            vm.currentComponent = CallIn
          }
          // 来电超时未接听，拨打方 系统自动 挂断时，接受方这边因为状态没有同步更新，所以需要手动切换
		  // 平台系统自动 挂断时间为3.05分钟，但是呼叫与接收方会存在网络差异会有0.5秒左右，所以接收方这个定时关闭时间设置比自动挂断时间少0.5秒
          setTimeout(function handleTimeoutCall() {
            if (call.status === 'started' || call.status === 'ringing') {
              vm.handleCallComplete(call)
            }
          }, 3 * 60 * 1000)
        } else if (call.direction === call.CALL_DIRECTION.OUTBOUND) {
          // 切换电话界面
		  vm.currentComponent = CallOut
          vm.$store.commit('setCallIniting', false)
        }
      })
    },
    // 监听结束
    onCompletedCallStatus() {
      const vm = this
      vm.app.on('call:status:changed', (call) => {
		console.log("Communication200行输出call:status状态   " + call.status)
		
		if(call.status == 'answered'){
			//获取实际接听时间post到后台
			var time = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
			var vonageAnswerTime_Time = {
				conversation_uuid: call.conversation.id,
				anstime:time
			}
			this.post_vonageAnswerTime(vonageAnswerTime_Time)
		}
		
        if (!isCompleted(call.status)) return
        vm.handleCallComplete(call)
		//获取实际状态post到后台
		var vonageStatus_log = {
			conversation_uuid: call.conversation.id,
			status:call.status
		}
		this.post_vonageStatus(vonageStatus_log)
		vm.StopFlashing()
        /**
        * @注意 这里的call.status不一定有completed状态
        */
      })
    },
    // token过期
    onTokenExpired() {
      this.app.on('system:error:expired-token', 'NXM-errors', () => {
        this.$store.commit('clearToken')
        this.$router.push('/user')
      })
    },
	// 处理呼叫完成
    handleCallComplete(call) {
      const vm = this
      const isCurrentCall = call === vm.currentCall
      vm.$store.commit('delCall', { isCurrentCall, call })
      // 来电状况下，添加来电记录
      if (call.direction === call.CALL_DIRECTION.INBOUND) {
        vm.addLog(call)
      }
      if (isCurrentCall) {
        vm.currentComponent = Dial
        vm.$nextTick(function() {
		  // 看是否有未接来电
          // 通话结束后观察通话的length
          if (vm.callList.length) {
            vm.currentComponent = CallIn
            // console.log('切换为callin面板')
          }
        })
      }
    },
    toCommunicationRoute() {
      const vm = this
      if (vm.$route.path.indexOf('/communication') !== 0) {
        vm.$router.push('/communication') 
        console.log('跳转commu') 
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1000px){
  .communication{
    display: flex;
    .communication-log{
      width: 200px;
    }
    .communication-operation{
      width:735px;
      .info{
        position: relative;
        height: 100px;
        .waiting-tag{
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }
    }
    .el-icon-loading{
      font-size: 25px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 999px){
  .communication{
    .info{
      position: relative;
      height: 60px;
      .waiting-tag{
        position: absolute;
        right: 20px;
        top: 10px;
      }
    }
  }
}

.v-enter-active {
  transition: all .3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.v-leave-active {
  transition: all .3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.v-enter{
  transform: translateX(-735px);
  opacity: 0;
}
.v-leave-to{
  transform: translateX(735px);
  opacity: 0;
}
</style>

