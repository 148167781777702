const COMPLETED_STATUS = ['failed', 'cancelled', 'rejected', 'busy', 'timeout', 'completed', 'unanswered']

const STARTED_STATUS = ['started', 'ringing']

export function isCompleted(status) {
  return COMPLETED_STATUS.includes(status)
}

export function isStarted(status) {
  return STARTED_STATUS.includes(status)
}

